/* eslint-disable no-void */
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import './audio-input.component.scss';
import { EIcon, Icon } from './icon.component';
import { Api } from '../api/api';
import { ERecordingLanguage, Recording, RecordingItem, RecordingTranscript } from 'shared';
import { FileInput } from './file-input.component';
import { getStorageUrl } from './storage.helper';
import { OnlySilenceRecordedException } from '../api/types';
import { useTranslation } from 'react-i18next';

const ALLOWED_MIME_TYPES = [
  'audio/mpeg3',
  'audio/mpeg',
  'audio/x-mpeg-3',
  'audio/x-mpeg',
  'audio/wav',
  'audio/wave',
  'audio/x-wav',
  'audio/aac',
  'audio/flac',
];

const ALLOWED_FILE_EXTENSIONS = ['.mp3', '.wav', '.wave', '.acc', '.flac'];

interface IAudioItemInputProps {
  className?: string;
  transcribe?: boolean;
  speechContext?: string[];
  value?: RecordingItem | null;
  onChange: (value: RecordingItem | null) => void;
}

// https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Recording_API/Using_the_MediaStream_Recording_API

function getMediaRecorder(): Promise<MediaRecorder> {
  return new Promise((resolve, reject) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      reject(new Error('getUserMedia not supported'));
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        resolve(new MediaRecorder(stream));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function AudioItemInput({ className, transcribe, speechContext, value, onChange }: IAudioItemInputProps): JSX.Element {
  const [recordingNotPossibleError, setRecordingNotPossibleError] = useState<string | null>(null);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const [previewError, setPreviewError] = useState<string | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [lastRecordingFilename, setLastRecordingFilename] = useState<string | null>(value?.filename || null);
  const [, setLastRecordingSourceFilename] = useState<string | null>(value?.sourceFilename || null);
  const [lastRecordingName, setLastRecordingName] = useState<string | null>(value?.name || null);
  const [, setLastRecordingTranscript] = useState<RecordingTranscript | null>(value?.transcript || null);
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const [isProcessingRecording, setIsProcessingRecording] = useState<boolean>(false);
  const audioEl = useRef<HTMLAudioElement>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [holdingRecordButton, setHoldingRecordButton] = useState(false);
  const dummyFile = useMemo(() => {
    if (lastRecordingName) {
      return { name: lastRecordingName };
    }
    return null;
  }, [lastRecordingName]);

  useEffect(() => {
    setLastRecordingFilename(value?.filename || null);
    setLastRecordingSourceFilename(value?.sourceFilename || null);
    setLastRecordingName(value?.name || null);
    setLastRecordingTranscript(value?.transcript || null);
  }, [value]);

  const updateIsPreviewing = useCallback(() => {
    setIsPreviewing(!!audioEl.current && audioEl.current.duration > 0 && !audioEl.current.paused);
  }, [audioEl.current]);

  const stopPreview = () => {
    if (!audioEl.current) {
      return;
    }

    audioEl.current.pause();
    audioEl.current.currentTime = 0;
  };

  const handleDelete = () => {
    stopPreview();
    setLastRecordingFilename(null);
    setLastRecordingSourceFilename(null);
    setLastRecordingName(null);
    setLastRecordingTranscript(null);

    // Delete request?
    onChange(null);
  };

  useEffect(() => {
    /*
      A recording is triggered by having mediaRecorder defined and holdingRecordButton set to true.
      Any other case will stop the recording (if active)
    */

    if (!holdingRecordButton && mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      setMediaRecorder(null);
      return;
    } else if (!holdingRecordButton || !mediaRecorder) {
      return;
    }

    new Promise<Blob>((resolve, reject) => {
      mediaRecorder.onstart = () => {
        setIsRecording(true);
      };

      let chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      mediaRecorder.onstop = () => {
        const recording = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        chunks = [];
        setIsRecording(false);
        resolve(recording);
      };

      mediaRecorder.onerror = (err) => {
        setIsRecording(false);
        reject(err);
      };

      mediaRecorder.start();
    })
      .catch(err => {
        console.error('Failed to record audio', err);
        setRecordingNotPossibleError('Geluid opnemen is mislukt.');
        return null;
      })
      .then(recording => {
        if (!recording) {
          return Promise.resolve(null);
        }
        setIsProcessingRecording(true);
        const file = new File([recording], 'Opname');
        return Api.uploadRecording(file, file.name, { transcribe, speechContext });
      })
      .then(recording => {
        if (!recording) {
          return Promise.resolve();
        }

        return onChange(recording);
      })
      .catch(err => {
        if (err instanceof OnlySilenceRecordedException) {
          setFileUploadError('Enkel stilte gedetecteerd, is de opnameapparatuur correct geconfigureerd?');
        } else {
          console.error('Failed to convert recording', err);
          setFileUploadError('Opname kon niet worden verwerkt');
        }
      })
      .finally(() => {
        setIsProcessingRecording(false);
      });
  }, [holdingRecordButton, mediaRecorder]);

  const handleRecord = () => {
    setFileUploadError(null);
    setHoldingRecordButton(true);

    if (mediaRecorder) {
      return;
    }

    getMediaRecorder()
      .then(mediaRecorderInstance => {
        setRecordingNotPossibleError(null);
        setMediaRecorder(mediaRecorderInstance);
      })
      .catch(err => {
        console.error('Cannot use media recorder', err);
        setRecordingNotPossibleError('Geluid opnemen is niet mogelijk of toegestaan op dit apparaat.');
      });
  };

  const handleStopRecord = () => {
    setHoldingRecordButton(false);
  };

  const sanitizeString = (input: string): string =>
    // eslint-disable-next-line no-control-regex, implicit-arrow-linebreak
    input.replace(/[^\x00-\x7F]/g, '');


  const processAudioFile = (file: File | null) => {
    if (!file) {
      return;
    }

    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      setFileUploadError(`Geüploade bestandstype niet van ondersteund formaat. Gebruik ${ALLOWED_FILE_EXTENSIONS.join(', ')}`);
      console.warn('Uploaded filetype not supported:', file.type);
      return;
    }

    setIsProcessingRecording(true);
    Api.uploadRecording(file, sanitizeString(file.name), { transcribe, speechContext })
      .then(result => onChange(result))
      .catch(err => {
        if (err instanceof OnlySilenceRecordedException) {
          setFileUploadError('Enkel stilte gedetecteerd in audiobestand');
        } else {
          console.error('Failed to convert file', file, err);
          setFileUploadError('Audiobestand kon niet worden geconverteerd');
        }
      })
      .finally(() => {
        setIsProcessingRecording(false);
      });
  };

  const handleFileChange = (file: File | null) => {
    if (!file) {
      handleDelete();
      return;
    }
    processAudioFile(file);
  };

  const togglePreview = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!audioEl.current) {
      setPreviewError('Audio player kon niet geladen worden');
      return;
    }

    if (audioEl.current.duration > 0 && !audioEl.current.paused) {
      stopPreview();
    } else {
      audioEl.current.play()
        .catch(err => {
          console.error('Failed to play audio', err);
          setPreviewError('Audio kon niet worden afgespeeld');
        });
    }
  };

  return <FileInput className={className} value={dummyFile} processing={isProcessingRecording} error={previewError || fileUploadError || recordingNotPossibleError} allowedMimeTypes={ALLOWED_MIME_TYPES} allowedFileExtension={ALLOWED_FILE_EXTENSIONS} onChange={handleFileChange}>
    <button
      className={classNames('file-input__button', 'audio-input__record-button', { 'audio-input__record-button--recording': isRecording })}
      onClick={e => e.stopPropagation()}
      onMouseDown={handleRecord}
      onMouseUp={handleStopRecord}
      onMouseOut={handleStopRecord}
    >
      {!recordingNotPossibleError && <>
        <Icon>{isRecording ? EIcon.CIRCLE : EIcon.MIC}</Icon>
        <span className="file-input__button-label">{isRecording ? 'Laat los om te stopppen' : 'Opnemen'}</span>
      </>}
      {recordingNotPossibleError && <>
        <Icon>{EIcon.MIC_OFF}</Icon>
        <span className="file-input__button-label">{recordingNotPossibleError}</span>
      </>}
    </button>

    <audio ref={audioEl} className="audio-input__playback" controls src={lastRecordingFilename ? getStorageUrl(lastRecordingFilename) : undefined} autoPlay={false} onPlay={updateIsPreviewing} onPause={updateIsPreviewing} onAbort={updateIsPreviewing} onEnded={updateIsPreviewing} />
    {lastRecordingFilename && <>
      <button className="file-input__button" onClick={togglePreview}>
        <Icon>{isPreviewing ? EIcon.STOP_CIRCLE : EIcon.PLAY_CIRCLE}</Icon>
        <span className="file-input__button-label">{isPreviewing ? 'Stoppen' : 'Afspelen'}</span>
      </button>
    </>}
  </FileInput>;
}

interface IAudioInputProps {
  className?: string;
  allowedLanguages?: ERecordingLanguage[]
  transcribe?: boolean;
  speechContext?: string[];
  value?: Recording | null;
  onChange: (value: Recording | null) => void;
}
export function AudioInput({ className, allowedLanguages, transcribe, speechContext, value, onChange }: IAudioInputProps): JSX.Element {
  const { t } = useTranslation();
  const langs = allowedLanguages || Object.values(ERecordingLanguage);

  const handleChange = (lang: ERecordingLanguage, itemValue: RecordingItem | null) => {
    const newValue = { ...value };
    newValue[lang] = itemValue ?? undefined;

    const allNull = Object.values(newValue).every(item => !item);
    if (allNull) {
      onChange(null);
    } else {
      onChange(newValue);
    }
  };

  return <div className={classNames('multi-audio-input', className)}>
    {langs.map(lang => <div className="multi-audio-input__wrapper">
      <span className="multi-audio-input__lang-label">{t(`common:language:${lang}`)}</span>
      <AudioItemInput key={lang} transcribe={transcribe} speechContext={speechContext} value={value ? value[lang] : undefined} onChange={(value) => handleChange(lang, value)} />
    </div>)}
  </div>;
}
